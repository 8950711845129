// 防抖和节流
export const debAndThr = {
  methods: {
    // 防抖debounce
    deb_(fn, wait = 1000) {
      let timer;
      return function () {
        let context = this;
        let args = arguments;
        if (timer) clearTimeout(timer);
        timer = setTimeout(() => {
          fn.apply(context, args);
        }, wait)
      }
    },
    // 节流throttle
    thr_(fn, wait = 1000) {
      let timer;
      return function () {
        if (timer != null) return;
        let context = this;
        let args = arguments;
        fn.apply(context, args);
        timer = setTimeout(() => {
          timer = null;
        }, wait);
      }
    },
  }
}
export const getcodefunc = {
  data() {
    return {
      //   验证码变量
      countText: {
        count: "59",
        click: "获取验证码"
      },
      isDisabled: false
    }
  },
  methods: {
    //   验证码方法
    // 倒计时
    countTime() {
      const TIME_COUNT = 60; //倒计时60秒
      if (!this.timer) {
        this.countText.count = TIME_COUNT;
        this.isDisabled = true;
        this.timer = setInterval(() => {
          if (this.countText.count > 0 && this.countText.count <= TIME_COUNT) {
            this.countText.count--;
          } else {
            this.isDisabled = false;
            clearInterval(this.timer);
            this.timer = null;
          }
        }, 1000);
      }
    },
    // 点击获取短信验证码
    async getCode(eventType) {
      let type = this.activeType;
      this.$refs["formData"].validateField(type, phoneError => {
        //验证邮箱是否正确
        if (!phoneError) {
          const reg = /^1[3|4|5|6|7|8][0-9]\d{8}$/;
          if (reg.test(this.formData.phone)) {
            this.activeType = "phone";
          } else {
            this.activeType = "email";
          }
          type = this.activeType;
          console.log("手机号和邮箱正确");
          this.countTime();
          this.getCodePost(eventType, type);
        } else {
          this.$message({
            message: '请检查手机号或邮箱！',
            type: "error",
            offset: 40
          });
          return false;
        }
      });
    },
    // 点击获取短信验证码,个人中心
    async getCenterCode(formname, activeType, eventType) {
      this.$refs[formname].validateField(activeType, phoneError => {
        //验证邮箱是否正确
        if (!phoneError) {
          console.log("手机号和邮箱正确");
          this.countTime();
          this.getCodePost(eventType, activeType);
        } else {
          this.$message({
            message: '请检查手机号或邮箱！',
            type: "error",
            offset: 40
          });
          return false;
        }
      });
    },
    // 请求短信验证码
    async getCodePost(eventType, activeType) {
      if (activeType == "phone") {
        let params = {
          mobile: this.formData.phone,
          event: eventType
        };
        let res = await this.$api.https.getSmsSend(params);
        if (res.code) {
          this.$message({
            message: "请耐心等待您的手机验证码",
            type: "success",
            offset: 40
          });
        } else {
          this.$message({
            message: res.msg,
            type: "error",
            offset: 40
          });
        }
      } else if (activeType == "email") {
        let params = {
          email: this.formData.email || this.formData.phone,
          event: eventType
        };
        let res = await this.$api.https.getEmailSend(params);
        if (res.code) {
          this.$message({
            message: "请耐心等待您的邮箱验证码",
            type: "success",
            offset: 40
          });
        } else {
          this.$message({
            message: res.msg,
            type: "error",
            offset: 40
          });
        }
      }
    },
  }
}
// 登录
export const loginfunc = {
  methods: {
    // 登录成功
    success(index) {
      switch (index) {
        case 1:
          // 返回上一页
          this.$router.go(-1)
          break;
        case 2:
          // 跳转到我的
          this.$router.replace({
            path: '/my'
          })
          break;
        case 3:
          // 跳转到我的
          this.$router.replace({
            path: '/login'
          })
          break;
        case 4:
          // 跳转到我的
          this.$store.state.vuex_user = {
            avatar: ""
          }; //清除vuex_user
          this.$store.state.vuex_token = ""; //清除用户token
          localStorage.setItem('lifeData', '');
          this.$router.replace({
            path: '/login'
          })
          break;
        case 5:
          // 跳转到我的
          this.$router.replace({
            path: '/Home'
          })
        default:
          this.$router.replace({
            path: '/'
          })
          break;
      }

    }
  }
}
// 判断用户协议是否选择
export const checkAgreement = {
  data() {
    return {
      // 是否选择用户协议
      flag: false
    }
  },
  methods: {
    // 用户是否点击用户隐私
    chooseAgreement(flag) {
      this.flag = flag;
    },
    // 校验用户是否点击用户隐私
    checkChoose() {
      if (!this.flag) {
        this.$message({
          message: "请勾选隐私政策和用户协议",
          type: "error",
          offset: 40
        });
        return false;
      }
      return true;
    },
  }
}
// 前往搜索页面
export const search = {
  data() {
    return {
      search: ''
    }
  },
  methods: {
    toSearch(value) {
      this.$router.push({
        path: "/search",
        query: {
          search: this.search || value || '',
          selectValue: this.selectValue || '',
        }
      });
    }
  }
}
// 返回顶部
export const toTop = {
  data() {
    return {
      scrollTop: 0
    }
  },
  mounted() {
    window.addEventListener("scroll", this.scrollToTop, true);
  },
  destroyed() {
    window.removeEventListener("scroll", this.scrollToTop);
  },
  methods: {
    backTop() {
      const that = this;
      let timer = setInterval(() => {
        // console.log("返回顶部");
        let ispeed = Math.floor(-that.scrollTop / 5);
        document.documentElement.scrollTop = document.body.scrollTop =
          that.scrollTop + ispeed;
        if (that.scrollTop === 0) {
          clearInterval(timer);
        }
      }, 16);
    },
    scrollToTop() {
      const that = this;
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.scrollTop = scrollTop;
    }
  }
}
